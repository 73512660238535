import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import BankData from '@/config/bankData';
import { BankCardList } from '@/apis/bankCards';
import { BankCardListResponse } from '@/types/bankCards';

export const useBankCardsStore = defineStore(
  'bankCards',
  () => {
    const BankList = ref<any>();
    const bankList = computed(() => BankList.value);
    const bankCardList = ref<BankCardListResponse['banks']>();

    async function getBankList() {
      const map: any = {};
      BankData.map((item) => {
        const first = item.bankName[0];
        if (!map[first]) {
          map[first] = [item];
        } else {
          map[first].push(item);
        }
      });
      BankList.value = {
        letterBankGroup: map,
        list: BankData.sort((a, b) => a.bankName.localeCompare(b.bankName)),
      };
    }

    async function getBankCardList() {
      const res = await BankCardList({ sort: 'updated_at', order: 'DESC' });
      bankCardList.value = res?.data?.banks ?? [];
    }

    return {
      BankList,
      bankList,
      bankCardList,
      getBankList,
      getBankCardList,
    };
  },
  {
    persist: {
      key: 'BankCards',
      pick: ['BankList'],
    },
  }
);
