import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hasHeader: true,
      bgClass: 'login',
    },
    component: () => import('@/pages/Login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      hasHeader: true,
      title: '注册',
      i18nKey: 'Login.Register',
      bgClass: 'login',
    },
    component: () => import('@/pages/Register'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页',
      i18nKey: 'Base.Home',
      hasHeader: true,
      hasSideber: true,
    },
    component: () => import('@/pages/Home'),
  },
  {
    path: '/menuSidebar',
    name: 'menuSidebar',
    meta: {
      // hasHeader: true,
      keepAlive: true,
      transition: 'slide'
    },
    component: () => import('@/pages/MenuSidebar'),
  },
  {
    path: '/recommend',
    name: 'recommend',
    meta: {
      title: '推荐',
      i18nKey: 'Base.Explore',
      bgClass: 'base',
      hasHeader: true,
      hasSideber: true,
    },
    component: () => import('@/pages/Recommend'),
  },
  {
    path: '/mission',
    meta: {},
    children: [
      {
        path: '',
        name: 'mission',
        meta: {
          title: '任务大厅',
          i18nKey: 'Base.Tasks',
          hasHeader: true,
          hasSideber: true,
        },
        component: () => import('@/pages/Mission'),
      },
      {
        path: 'group',
        name: 'missionGroup',
        meta: {
          title: '任务包',
          i18nKey: 'Mission.Packages',
          hasHeader: true,
          bgClass: 'mission',
        },
        component: () => import('@/pages/Mission/group.vue'),
      },
      {
        path: 'promotion',
        name: 'missionPromotion',
        meta: {
          title: '推广中心',
          i18nKey: 'Mission.Promotion',
          hasHeader: true,
          bgClass: 'mission',
        },
        component: () => import('@/pages/Mission/promotion.vue'),
      },
      {
        path: 'rules',
        name: 'missionRules',
        meta: {
          title: '规则说明',
          i18nKey: 'Mission.Rules',
          hasHeader: true,
          bgClass: 'mission',
        },
        component: () => import('@/pages/Mission/rules.vue'),
      },
    ],
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '我的',
      i18nKey: 'Base.Me',
      hasHeader: true,
      hasSideber: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/User'),
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '搜索',
      i18nKey: 'Search.Search',
      hasHeader: true,
    },
    component: () => import('@/pages/Search'),
  },
  {
    path: '/videos',
    name: 'videos',
    meta: {
      // hasHeader: true,
    },
    children: [
      {
        path: 'detail/:id',
        name: 'videosDetail',
        meta: {
          hasHeader: { logo: true },
        },
        component: () => import('@/pages/Videos/detail.vue'),
      },
      {
        path: 'play/:id/:episodesId',
        name: 'videosPlay',
        meta: {
          // hasHeader: { bgTransparent: true },
        },
        component: () => import('@/pages/Videos/play.vue'),
      },
    ],
  },
  {
    path: '/security',
    name: 'security',
    meta: {
      hasHeader: true,
      bgClass: 'base',
    },
    children: [
      {
        path: '/security',
        name: 'securityList',
        meta: {
          title: '账号安全',
          i18nKey: 'Security.AccountSecurity',
        },
        component: () => import('@/pages/Security'),
      },
      {
        path: 'certifications',
        name: 'certifications',
        meta: {
          title: '实名认证',
          i18nKey: 'Mission.IDVerification',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Security/certifications.vue'),
      },
      {
        path: 'forgot',
        name: 'forgot',
        meta: {
          title: '忘记密码',
          i18nKey: 'Login.Forgot',
        },
        component: () => import('@/pages/Security/forgot.vue'),
      },
      {
        path: 'verify',
        name: 'verify',
        meta: {
          title: '安全认证',
          i18nKey: 'Security.Verification',
        },
        component: () => import('@/pages/Security/verify.vue'),
      },
      {
        path: 'modify',
        name: 'modify',
        meta: {
          title: '更改',
          i18nKey: 'Security.Change',
        },
        component: () => import('@/pages/Security/modify.vue'),
      },
      {
        path: 'modifyPassword',
        name: 'modifyPassword',
        meta: {
          title: '重设密码',
          i18nKey: 'Security.ResetPsd',
        },
        component: () => import('@/pages/Security/modifyPassword.vue'),
      },
      {
        path: 'delete',
        name: 'delete',
        meta: {
          title: 'Hapus Akun',
          isAuth: true,
        },
        component: () => import('@/pages/Security/delete.vue'),
      },
    ],
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      hasHeader: true,
    },
    children: [
      {
        path: 'language',
        name: 'language',
        meta: {
          title: '语言切换',
          i18nKey: 'User.Language',
        },
        component: () => import('@/pages/Settings/language.vue'),
      },
    ],
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      title: '历史记录',
      i18nKey: 'User.History',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/History'),
  },
  {
    path: '/favorites',
    name: 'favorites',
    meta: {
      title: '我的收藏',
      i18nKey: 'Favorites.Favorites',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/Favorites'),
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      hasHeader: true,
      bgClass: 'base',
    },
    children: [
      {
        path: '',
        name: 'myOrders',
        meta: {
          title: '我的订单',
          i18nKey: 'Orders.Orders',
        },
        component: () => import('@/pages/Orders'),
      },
      {
        path: 'info/:type/:id',
        name: 'orderInfo',
        meta: {
          title: '订单详情',
          i18nKey: 'Orders.OrderDetails',
        },
        component: () => import('@/pages/Orders/info.vue'),
      },
    ],
  },
  {
    path: '/integral',
    name: 'integral',
    meta: {
      hasHeader: true,
    },
    children: [
      {
        path: '',
        name: 'myIntegral',
        meta: {
          title: '硬币',
          i18nKey: 'Integral.Coin',
        },
        component: () => import('@/pages/Integral'),
      },
      {
        path: 'transfer',
        name: 'integralTransfer',
        meta: {
          title: '硬币转让',
          i18nKey: 'Integral.CoinTransfer',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Integral/transfer.vue'),
      },
      {
        path: 'withdrawal',
        name: 'integralWithdrawal',
        meta: {
          title: '硬币提币',
          i18nKey: 'Integral.CoinWithdrawal',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Integral/withdrawal.vue'),
      },
      {
        path: 'withdrawal/:no',
        name: 'integralWithdrawalDetail',
        meta: {
          title: '硬币明细',
          i18nKey: 'Integral.CoinWithdrawal',
          hasHeader: true,
          bgClass: 'base',
        },
        component: () => import('@/pages/Integral/withdrawalDetail.vue'),
      },
    ],
  },
  {
    path: '/cards',
    name: 'myCards',
    meta: {
      title: '卡券',
      i18nKey: 'Cards.Coupons',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/Cards'),
  },
  {
    path: '/Coupons',
    name: 'coupons',
    meta: {
      title: '卡券',
      i18nKey: 'Cards.Coupons',
      hasHeader: true,
      bgClass: 'base',
    },
    component: () => import('@/pages/Coupons'),
  },
  {
    path: '/payments',
    name: 'payments',
    meta: {
      hasHeader: true,
    },
    children: [
      {
        path: 'callback',
        name: 'callback',
        meta: {
          title: 'callback',
          i18nKey: 'Payments.Pay',
        },
        component: () => import('@/pages/Payments/callback.vue'),
      },
      {
        path: 'pay/:id',
        name: 'paymentsPay',
        meta: {
          title: '确认支付',
          i18nKey: 'Payments.Confirm',
        },
        component: () => import('@/pages/Payments/pay.vue'),
      },
    ],
  },
  {
    path: '/protocols',
    name: 'protocols',
    meta: {
      hasHeader: true,
    },
    children: [
      {
        path: 'distributor',
        name: 'protocolsDistributor',
        meta: {
          title: '分销商',
          i18nKey: 'Mission.Distributor',
        },
        component: () => import('@/pages/Protocols/distributor.vue'),
      },
      {
        path: 'service',
        name: 'protocolsService',
        meta: {
          title: '用户服务',
          i18nKey: 'Protocols.UserService',
        },
        component: () => import('@/pages/Protocols/service.vue'),
      },
      {
        path: 'privacy',
        name: 'protocolsPrivacy',
        meta: {
          title: '隐私政策',
          i18nKey: 'Protocols.PrivacyPolicy',
        },
        component: () => import('@/pages/Protocols/privacy.vue'),
      },
      {
        path: 'employment',
        name: 'protocolsEmployment',
        meta: {
          title: '灵活就业合作伙伴协议',
          i18nKey: 'Protocols.Employment',
        },
        component: () => import('@/pages/Protocols/employment.vue'),
      },
      {
        path: 'authorization',
        name: 'protocolsAuthorization',
        meta: {
          title: '版权授权协议',
          i18nKey: 'Protocols.Authorization',
        },
        component: () => import('@/pages/Protocols/authorization.vue'),
      },
      {
        path: 'copyrightSign',
        name: 'protocolsCopyrightSign',
        meta: {
          title: '灵活就业合作伙伴协议',
          i18nKey: 'Protocols.Employment',
        },
        component: () => import('@/pages/Protocols/copyrightSign.vue'),
      },
      {
        path: 'riskNotice',
        name: 'protocolsRiskNotice',
        meta: {
          title: '实名认证风险告知',
          i18nKey: 'Protocols.RiskNotice',
        },
        component: () => import('@/pages/Protocols/riskNotice.vue'),
      },
    ],
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: '关于我们',
      i18nKey: 'User.AboutUs',
      hasHeader: true,
    },
    component: () => import('@/pages/AboutUs'),
  },
  {
    path: '/version',
    name: 'version',
    meta: {
      title: '版本',
      i18nKey: 'Common.Version',
      hasHeader: true,
    },
    component: () => import('@/pages/Version'),
  },
  {
    path: '/bankCards',
    name: 'bankCards',
    meta: {
      title: '银行账户管理',
      hasHeader: true,
    },
    children: [
      {
        path: '',
        name: 'bankCardList',
        meta: {
          i18nKey: 'BankCards.bankCardList',
        },
        component: () => import('@/pages/BankCards'),
      },
      {
        path: 'add',
        name: 'bankCardAdd',
        meta: {
          title: '新增银行账户',
          i18nKey: 'BankCards.BankCardAdd',
        },
        component: () => import('@/pages/BankCards/add.vue'),
      },
    ]
  },
  {
    path: '/copyrights',
    name: 'copyrights',
    meta: {
      title: '版权包',
      i18nKey: 'CopyrightPacks.Packs',
      hasHeader: true,
    },
    children: [
      {
        path: 'packs',
        name: 'copyrightsPacks',
        meta: {
          title: '版权包',
          i18nKey: 'CopyrightPacks.Packs',
          hasHeader: true,
        },
        component: () => import('@/pages/Copyrights/packs.vue'),
      },
    ]
  },
  {
    path: '/materials',
    name: 'materials',
    meta: {
      title: '我的素材',
      i18nKey: 'User.Materials',
      hasHeader: true,
    },
    children: [
      {
        path: '',
        name: 'materialsPacks',
        meta: {
          title: '版权包',
          i18nKey: 'CopyrightPacks.Packs',
          hasHeader: true,
        },
        component: () => import('@/pages/Materials'),
      },
      {
        path: 'secondary',
        name: 'materialsSecondary',
        meta: {
          title: '短剧二创',
          i18nKey: 'CopyrightPacks.DramaSecondary',
          hasHeader: true,
        },
        component: () => import('@/pages/Materials/secondary.vue'),
      },
      {
        path: 'secondaryUpload',
        name: 'materialsSecondaryUpload',
        meta: {
          title: '上传二创素材',
          i18nKey: 'Materials.SecondaryUpload',
          hasHeader: true,
        },
        component: () => import('@/pages/Materials/secondaryUpload.vue'),
      },
    ]
    
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    meta: {
      title: '教程',
      i18nKey: 'Tutorials.Tutorial',
      hasHeader: true,
    },
    children: [
      {
        path: 'downloadMaterial',
        name: 'TutorialsSecondary',
        meta: {
          title: '下载素材教程',
          i18nKey: 'Tutorials.DownloadMaterial',
          hasHeader: true,
        },
        component: () => import('@/pages/Tutorials/downloadMaterial.vue'),
      },
    ]
    
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: {
      name: 'home',
    },
  },
];

export default routes;
