import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { Account, UpdateParams } from '@/types/account';
import { AccountGet, AccountUpdate, CooperationAgreementGet } from '@/apis/account';
import { encryptData, decryptData } from '@/plugins/cryptoJs';

export const useAccountStore = defineStore(
  'account',
  () => {
    const UserInfo = ref<any>();
    const userInfo = computed(() =>
      !UserInfo.value
        ? null
        : __APP_MODE__ === 'development'
          ? UserInfo.value
          : (JSON.parse(decryptData(UserInfo.value)) as Account)
    );
    const isCooperationAgreement = ref<boolean>(false);

    async function getAccount() {
      const res = await AccountGet({});
      if (res?.code === 200) {
        const { data } = res;
        const info = {
          ...data,
          setPayPassword: (data?.verified_meta & 16) > 0,
          formatName: data?.nickname || `User_${data?.user_id}`,
        };
        UserInfo.value = __APP_MODE__ === 'development' ? info : encryptData(JSON.stringify(info));
        return info;
      } else {
        return Promise.reject({});
      }
    }

    async function updateAccount(params: UpdateParams) {
      const res = await AccountUpdate(params);
      res?.code === 200 && getAccount();
      return res;
    }

    function reset() {
      UserInfo.value = null;
    }

    async function getCooperationAgreement() {
      localStorage.removeItem('Account');
      const res = await CooperationAgreementGet({});
      isCooperationAgreement.value = res?.code === 200 && res?.data?.is_sign;
    }

    return {
      UserInfo,
      userInfo,
      isCooperationAgreement,
      getAccount,
      updateAccount,
      reset,
      getCooperationAgreement,
    };
  },
  {
    persist: {
      key: 'Account',
      pick: ['UserInfo'],
    },
  }
);
