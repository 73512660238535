const ApisConfigEndpoint = (title: string, url: string) => ({ title, url });

export const login = {
  login: ApisConfigEndpoint('登录', '/v1/users/login/step1'),
  register: ApisConfigEndpoint('注册', '/v1/users/register'),
  logout: ApisConfigEndpoint('退出登录', '/v1/users/logout'),
  thirdAccountList: ApisConfigEndpoint('获取绑定的第三方账号列表', '/v1/user/third/list'),
  thirdLoginList: ApisConfigEndpoint('获取第三方登录链接', '/v1/user/third/login/link'),
  tiktokLoginCallback: ApisConfigEndpoint('抖音登录回调接口', '/v1/user/tiktok/login/callback'),
};

export const account = {
  get: ApisConfigEndpoint('获取用户信息', '/v1/users/get'),
  update: ApisConfigEndpoint('修改用户信息', '/v1/users/update'),
  change: ApisConfigEndpoint('修改用户信息（需要走安全验证）', '/v1/users/change'),
  cooperationAgreementCreate: ApisConfigEndpoint('签署合作协议', '/v1/user/cooperation_agreement/create'),
  cooperationAgreementGet: ApisConfigEndpoint('获取签署合作协议状态', '/v1/user/cooperation_agreement/get'),
};

export const security = {
  codesSend: ApisConfigEndpoint('发送验证码', '/v1/users/codes/send'),
  codesCheck: ApisConfigEndpoint('校验验证码', '/v1/users/codes/check'),
  messagesCheck: ApisConfigEndpoint('校验短信/邮件', '/v1/users/messages/check'),
  messagesSend: ApisConfigEndpoint('发送短信/邮件', '/v1/users/messages/send'),
  tokenCheck: ApisConfigEndpoint('安全验证', '/v1/users/security/token/check'),
  tokenGet: ApisConfigEndpoint('获取安全令牌', '/v1/users/security/token/get'),
  upload: ApisConfigEndpoint('上传', '/v1/files/upload'),
  certificationsGet: ApisConfigEndpoint('获取认证信息', '/v1/certifications/get'),
  certificationsSubmit: ApisConfigEndpoint('提交认证', '/v1/certifications/submit'),
  usersDelete: ApisConfigEndpoint('注销账号', '/v1/users/delete'),
  forgetReset: ApisConfigEndpoint('忘记密码', '/v1/users/password/forget'),
};

export const mission = {
  groupLink: ApisConfigEndpoint('获取Whatsapp群链接', '/v1/task/group/link'),
  list: ApisConfigEndpoint('获取用户任务列表', '/v1/task/list'),
  update: ApisConfigEndpoint('更新用户任务', '/v1/task/update'),
  activeList: ApisConfigEndpoint('获取生效中的任务包列表', '/v1/task/plan/active/list'),
  planList: ApisConfigEndpoint('获取任务包列表', '/v1/task/plan/list'),
};

export const distributor = {
  apply: ApisConfigEndpoint('分销商申请', '/v1/distributor/get'),
  statusGet: ApisConfigEndpoint('获取分销商申请状态', '/v1/distributor/apply/status/get'),
  bind: ApisConfigEndpoint('绑定上级分销商', '/v1/distributor/bind'),
  detail: ApisConfigEndpoint('获取分销商信息', '/v1/distributor/info/get'),
  link: ApisConfigEndpoint('获取推广链接', '/v1/distributor/link/get'),
  search: ApisConfigEndpoint('搜索分销商', '/v1/distributor/search'),
  upgradeList: ApisConfigEndpoint('获取达人升级条件', '/v1/expert/upgrade/list'),
  contributionList: ApisConfigEndpoint('获取贡献值记录列表', '/v1/contribution/record/list'),
  memberList: ApisConfigEndpoint('获取团队成员列表', '/v1/team/member/list'),
  performanceList: ApisConfigEndpoint('团队业绩记录列表', '/v1/team/performance/record/list'),
  dividendsList: ApisConfigEndpoint('获取分红列表', '/v1/user/dividends/list'),
};

export const cards = {
  list: ApisConfigEndpoint('获取VIP卡列表', '/v1/plan/list'),
  recordList: ApisConfigEndpoint('获取VIP卡购买记录列表', '/v1/user/plan/list'),
};

export const integral = {
  estimateGet: ApisConfigEndpoint('获取预估硬币', '/v1/point/estimate/get'),
  get: ApisConfigEndpoint('获取用户硬币信息', '/v1/point/get'),
  ledger: ApisConfigEndpoint('获取硬币流水', '/v1/point/ledger/list'),
  frozen: ApisConfigEndpoint('获取硬币冻结记录', '/v1/point/frozen/list'),
  transfer: ApisConfigEndpoint('转让硬币', '/v1/point/transfer'),
  coinsList: ApisConfigEndpoint('获取硬币包列表', '/v1/point/pack/list'),
  settlementList: ApisConfigEndpoint('获取结算明细列表', '/v1/user/copyright_pack/settlement/list'),
};

export const orders = {
  create: ApisConfigEndpoint('创建订单', '/v1/order/create'),
  detail: ApisConfigEndpoint('获取订单详情', '/v1/order/get'),
  list: ApisConfigEndpoint('查询我的订单列表', '/v1/order/list'),
  pay: ApisConfigEndpoint('支付订单', '/v1/order/pay'),
  cancel: ApisConfigEndpoint('取消订单', '/v1/order/cancel'),
  refund: ApisConfigEndpoint('申请退款', '/v1/order/refund/apply'),
  refundCancel: ApisConfigEndpoint('取消退款申请', '/v1/order/refund/cancel'),
};

export const payments = {
  pay: ApisConfigEndpoint('支付订单', '/v1/order/pay'),
  stripeCheck: ApisConfigEndpoint('验证Stripe支付状态', '/v1/order/stripe/payment/check'),
  stripeCreate: ApisConfigEndpoint('创建Stripe支付意图', '/v1/order/stripe/payment/create'),
  inTheBagCheck: ApisConfigEndpoint('验证InTheBag支付状态', '/v1/order/in_the_bag/payment/check'),
  inTheBagCreate: ApisConfigEndpoint('创建InTheBag支付意图', '/v1/order/in_the_bag/payment/create'),
  monetapayCheck: ApisConfigEndpoint('验证Monetapay支付状态', '/v1/order/monetapay/check'),
  monetapayCreate: ApisConfigEndpoint('创建Monetapay支付意图', '/v1/order/monetapay/create'),
};

export const playlet = {
  groupList: ApisConfigEndpoint('获取短剧分组列表', '/v1/playlet/group/list'),
  groupInList: ApisConfigEndpoint('获取分组下的短剧列表', '/v1/playlet/group/in/list'),
  ledgerList: ApisConfigEndpoint('查询看剧记录', '/v1/playlet/ledger/list'),
  ledgerUpdate: ApisConfigEndpoint('更新用户看剧记录', '/v1/playlet/ledger/update'),
  episodeList: ApisConfigEndpoint('获取短剧剧集列表', '/v1/playlet/episode/list'),
  searchList: ApisConfigEndpoint('模糊搜索短剧列表', '/v1/playlet/search/list'),
  videoAuth: ApisConfigEndpoint('获取视频播放凭证', '/v1/playlet/video/auth/get'),
  collectAdd: ApisConfigEndpoint('添加用户收藏', '/v1/playlet/collect/add'),
  collectList: ApisConfigEndpoint('获取短剧收藏记录', '/v1/playlet/collect/list'),
  collectUpdate: ApisConfigEndpoint('更新用户收藏记录', '/v1/playlet/collect/update'),
  moreList: ApisConfigEndpoint('获取更多好剧', '/v1/playlet/more/list'),
  shareGet: ApisConfigEndpoint('获取分享链接', '/v1/playlet/share/get'),
};

export const ad = {
  recordCreate: ApisConfigEndpoint('创建广告观看记录', '/v1/playlet/ad/record/create'),
  recordUpdate: ApisConfigEndpoint('更新广告观看记录', '/v1/playlet/ad/record/update'),
};

export const bankCards = {
  create: ApisConfigEndpoint('添加银行卡', '/v1/user/bank/create'),
  list: ApisConfigEndpoint('获取银行卡列表', '/v1/user/bank/list'),
  modify: ApisConfigEndpoint('修改银行卡', '/v1/user/bank/modify'),
};

export const withdrawal = {
  create: ApisConfigEndpoint('创建提现申请', '/v1/user/withdrawal/create'),
  list: ApisConfigEndpoint('查询提现记录', '/v1/user/withdrawal/list'),
};

export const configurations = {
  rateGet: ApisConfigEndpoint('获取后台汇率配置', '/v1/point/rate/get'),
};

export const copyrightPacks = {
  list: ApisConfigEndpoint('获取版权包列表', '/v1/copyright_pack/list'),
  materialList: ApisConfigEndpoint('获取版权包素材列表', '/v1/user/copyright_material/list'),
  create: ApisConfigEndpoint('领取版权包', '/v1/user/copyright_pack/create'),
  get: ApisConfigEndpoint('获取用户当前版权包', '/v1/user/copyright_pack/get'),
};

export const materials = {
  list: ApisConfigEndpoint('获取版权包素材列表', '/v1/user/copyright_material/list'),
  secondCreationCreate: ApisConfigEndpoint('提交二创素材', '/v1/user/second_creation/create'),
  secondCreationDelete: ApisConfigEndpoint('删除我的二创素材列表', '/v1/user/second_creation/delete'),
  secondCreationList: ApisConfigEndpoint('获取我的二创素材列表', '/v1/user/second_creation/list'),
};
