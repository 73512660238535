import { createApi } from '.';
import { account } from './apiMapping';
import { Account, UpdateParams, ChangeParams, CooperationAgreementGetResponse } from '@/types/account';

export const AccountGet = createApi<unknown, Account>(account.get.url);
export const AccountUpdate = createApi<UpdateParams, unknown>(account.update.url);
export const AccountChange = createApi<ChangeParams, unknown>(account.change.url);

export const CooperationAgreementCreate = createApi<unknown, unknown>(account.cooperationAgreementCreate.url);
export const CooperationAgreementGet = createApi<unknown, CooperationAgreementGetResponse>(
  account.cooperationAgreementGet.url
);
