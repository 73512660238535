import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { showToast, closeToast } from 'vant';
import i18n from '@/plugins/i18n';
import { Create } from '@/apis/orders';
import { Pay, InTheBagCreate, MonetapayCreate } from '@/apis/payments';
import { InTheBagCreateParams, MonetapayCreateParams } from '@/types/payments';
import { CreateParams, PayInfo } from '@/types/orders';

export const usePaymentsStore = defineStore(
  'payments',
  () => {
    const showPayment = ref<boolean>(false);
    const PayOrderInfo = ref<PayInfo | null>(null);
    const payOrderInfo = computed<PayInfo | null>(() => PayOrderInfo.value);

    async function paymentOrder(params: CreateParams) {
      showToast({
        type: 'loading',
        message: i18n?.global?.t('Common.Processing'),
        forbidClick: true,
        duration: 0,
      });
      const res = await Create(params);
      let curRes: any = res;
      if (res?.code !== 200 || !res?.data?.no) {
        closeToast();
        return curRes;
      }
      const payRes = await Pay({ no: res?.data?.no, password: '' });
      if (payRes?.code !== 200) {
        curRes = payRes;
      }
      closeToast();
      return curRes;
    }

    async function inTheBagCreate(params: InTheBagCreateParams) {
      return await InTheBagCreate(params);
    }

    async function monetapayCreate(params: MonetapayCreateParams) {
      return await MonetapayCreate(params);
    }

    function setPayOrderInfo(item?: PayInfo) {
      PayOrderInfo.value = item || null;
      showPayment.value = !!item;
    }

    return {
      showPayment,
      paymentOrder,
      payOrderInfo,
      PayOrderInfo,
      setPayOrderInfo,
      inTheBagCreate,
      monetapayCreate,
    };
  },
  {
    persist: {
      key: 'Payments',
      pick: ['PayOrderInfo'],
    },
  }
);
