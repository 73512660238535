import { ref } from 'vue';
import { defineStore } from 'pinia';
import {  CopyrightPackGet, CopyrightPackCreate } from '@/apis/copyrightPacks';
import { CopyrightPackCreateParams, CopyrightPackGetResponse } from '@/types/copyrightPacks';

export const useCopyrightsStore = defineStore('copyrights', () => {
  const activePack = ref<CopyrightPackGetResponse['copyright_pack']>();

  async function getCopyrightPack() {
    const res = await CopyrightPackGet({});
    if (res?.code !== 200) return;
    activePack.value = res?.data?.copyright_pack;;
  }

  async function copyrightPackCreate(params: CopyrightPackCreateParams) {
    return await CopyrightPackCreate(params);
  }

  return {
    activePack,
    getCopyrightPack,
    copyrightPackCreate
  };
});
