import { ref } from 'vue';
import { defineStore } from 'pinia';
import { RateGet } from '@/apis/configurations';
import { RateGetResponse } from '@/types/configurations';

export const useConfigurationsStore = defineStore('configurations', () => {
  const rateGet = ref<RateGetResponse>();

  async function getRateGet() {
    const res = await RateGet({});
    if (res?.code !== 200) return;
    rateGet.value = res?.data;
  }

  return {
    getRateGet,
    rateGet,
  };
});
