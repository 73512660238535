import { createApi } from '.';
import { copyrightPacks } from './apiMapping';
import {
  CopyrightPackListResponse,
  CopyrightPackCreateParams,
  CopyrightPackGetResponse,
  MaterialListParams,
  MaterialListResponse,
} from '@/types/copyrightPacks';


export const CopyrightPackList = createApi<unknown, CopyrightPackListResponse>(copyrightPacks.list.url);
export const CopyrightPackCreate = createApi<CopyrightPackCreateParams, unknown>(copyrightPacks.create.url);
export const CopyrightPackGet = createApi<unknown, CopyrightPackGetResponse>(copyrightPacks.get.url);
export const MaterialList = createApi<MaterialListParams, MaterialListResponse>(copyrightPacks.materialList.url);
