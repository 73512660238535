import Dayjs from 'dayjs';
import i18n from '@/plugins/i18n';
import { hmacSha256 } from '@/plugins/cryptoJs';
import { timeToSeconds } from './format';

// 正则表达式
export const RulesPattern = {
  isInt: /^\d+$/,
  isNumberFloat: /^\d+(\.\d+)?$/,
  isNumber0_100: /^([1-9]?[0-9]|100)$/,
  isEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  isLNC: (min: number, max: number) => new RegExp(`^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z\\d]{${min},${max}}$`),
  isPassword: (min: number, max: number) =>
    new RegExp(`^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{${min},${max}}$`), // 数字字母特殊符号
};

// 复制文字到剪贴板
export function copyToClipboard(str: string): boolean {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).catch((err) => console.error('Failed to copy: ', err));
    return true;
  }

  const textArea = document.createElement('textarea');
  textArea.value = str;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    return true;
  } catch (err) {
    console.error('Failed to copy: ', err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

// 获取本地图片URL
export const getAssetUrl = (url: string, folders: string = 'images') =>{
  console.log(`../assets/${folders}/${url}`)
 return new URL(`../assets/${folders}/${url}`, import.meta.url).href;
}
  

export const getStaticFullUrl = (url: string) => {
  if (!url) return '';
  return __APP_STATIC_URL__ ? `${__APP_STATIC_URL__}/${url}` : `${window?.location?.origin}/static/${url}`;
};

/**
 * 获取文件的Base64编码
 * @param file - 要编码的文件
 * @returns Promise<string> - 返回Base64编码的字符串
 */
export function getFileBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const isMobile = (() =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))();

export const isIos = (() => /iPhone|iPad|iPod|iOS/i.test(navigator.userAgent))();

export const isAndroid = (() => /Android/i.test(navigator.userAgent))();

export const isXiaomi = (() =>
  /mi\s/i.test(navigator.userAgent) ||
  /mix\s/i.test(navigator.userAgent) ||
  /redmi/i.test(navigator.userAgent) ||
  /xiaomi/i.test(navigator.userAgent))();

export const diffTime = {
  day: (time: string, space: number = 7) => {
    const curTime = Dayjs().tz().unix();
    return (curTime - Number(time)) / (60 * 60 * 24) < space;
  },
};

// 打开外部url
export const openHref = (href: string) => {
  const eleLink = document.createElement('a');
  //设置链接
  eleLink.setAttribute('href', href);
  //新窗口打开链接
  eleLink.setAttribute('target', '_blank');
  //设置标签ID
  eleLink.setAttribute('id', 'reportpoint');

  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
};

// 读取文件
type XMLHttpRequestResponseType = '' | 'arraybuffer' | 'blob' | 'document' | 'json' | 'text';
export const readerFile = (url: string, type: XMLHttpRequestResponseType = 'blob') => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = type;
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        resolve('');
      }
    };
    xhr.send();
  });
};

// 解析字幕
export const parseSubtitles = (srt: string) => {
  const subtitles = [];
  const textSubtitles = srt.split('\n\n'); // 每条字幕的信息，包含了序号，时间，字幕内容

  for (let i = 0; i < textSubtitles.length; ++i) {
    const textSubtitle = textSubtitles[i].split('\n');

    if (textSubtitle.length >= 2) {
      const sn = textSubtitle[0]; // 字幕的序号
      const startTime = timeToSeconds(textSubtitle[1].split(' --> ')[0]); // 字幕的开始时间
      const endTime = timeToSeconds(textSubtitle[1].split(' --> ')[1]); // 字幕的结束时间
      let content = textSubtitle[2]; // 字幕的内容

      // 字幕可能有多行
      if (textSubtitle.length > 2) {
        for (let j = 3; j < textSubtitle.length; j++) {
          content += '\n' + textSubtitle[j];
        }
      }

      // Nomor pendaftaran: 9900003230206039
      if (endTime - startTime < 5 && !content.toLocaleLowerCase()?.includes('nomor')) {
        // 字幕对象
        subtitles.push({
          sn,
          startTime,
          endTime,
          content,
        });
      }
    }
  }

  return subtitles;
};

export const getSignInfo = (deviceId: string, url: string, body: object) => {
  const key = `${deviceId.slice(11, 21)}${deviceId.slice(21, 26)}${deviceId.slice(1, 6)}`;
  const timestamp = Dayjs().unix();
  const sign = hmacSha256(key, `${decodeURIComponent(url)}${timestamp}${JSON.stringify(body)}`);

  return {
    timestamp,
    sign,
  };
};

export const getI18nName = (type: string, options: any) => {
  const item = options.find((val: any) => type === val.value);
  return item ? i18n?.global?.t(item?.label) : '-';
};
