import Dayjs from 'dayjs';

// 格式化时间
export const formatTime = (time: number | string, format = 'MM/DD/YYYY HH:mm:ss', defaultValue = '-'): string => {
  const timestamp = typeof time === 'string' ? parseInt(time, 10) : time;
  return timestamp > 0 ? Dayjs(`${timestamp}`.length < 13 ? timestamp * 1000 : timestamp).format(format) : defaultValue;
};

/**
 * 邮箱脱敏
 * Example: test@example.com -> tes***@example.com
 * @param email - Email
 * @returns Masked email address or original string if invalid email
 */
export const maskEmail = (email: string): string => {
  if (!email || typeof email !== 'string') return '-';

  const emailRegex = /^([^@\s]+)@([\w.-]+)$/;
  const match = email.match(emailRegex);

  if (!match) return email;

  const [, local, domain] = match;
  const maskedLocal = local.slice(0, 3) + '*'.repeat(Math.max(local.length - 3, 1));

  return `${maskedLocal}@${domain}`;
};

/**
 * 手机脱敏
 * Example: 18800000000 -> 18****000
 * @param mobile - mobile
 * @returns Masked mobile number or original string if invalid mobile
 */
export const maskMobile = (mobile: string): string => {
  if (!mobile || typeof mobile !== 'string') return '-';

  const len = mobile.length;
  const maskedMobile = mobile.slice(0, 2) + '****' + mobile.slice(len - 3);

  return maskedMobile;
};

// (SRT)时间转换为秒
export const timeToSeconds = (time: string) => {
  const match = time.match(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/);
  if (match === null) throw Error(`time format error: ${time}`);

  const hours = Number(match[1]);
  const minutes = Number(match[2]);
  const seconds = Number(match[3]);
  const milliseconds = Number(match[4]);

  return hours * 60 * 60 + minutes * 60 + seconds + milliseconds / 1000;
};

// 价格格式化保留小数，默认2位
export const formatPriceFixed = (value: number | string | undefined, fixed = 2): string => {
  if (!value || !Number(value) || isNaN(Number(value))) return '0';
  const valueDecimal = value.toString().split('.')[1];
  if (!valueDecimal || valueDecimal?.length <= fixed) return value.toString();
  return Number(value).toFixed(fixed);
};

// // 价格格式化带单位
// export const formatPriceUnit = (value: number | string): string => {
//   if (!value || !Number(value) || isNaN(Number(value))) return '0';
//   const val = typeof value === 'string' ? Number(value) : value;
//   const thresholds = [
//     { limit: 1e9, suffix: 'B' },
//     { limit: 1e6, suffix: 'M' },
//     { limit: 1e4, suffix: 'W' },
//     { limit: 1e3, suffix: 'K' },
//   ];
//   for (const { limit, suffix } of thresholds) {
//     if (val >= limit) {
//       return formatPriceFixed(val / limit) + suffix;
//     }
//   }
//   return formatPriceFixed(val);
// };

// 价格格式化根据指定范围和后缀
export const formatPriceUnit = (value: number | string): string => {
  const val = Number(formatPriceFixed(value));
  if (!value) return '0';
  if (val < 1000) {
    return val.toFixed(2);
  } else if (val < 1000000) {
    return (val / 1000).toFixed(2) + 'K';
  } else if (val < 1000000000) {
    return (val / 1000000).toFixed(2) + 'M';
  } else if (val < 1000000000000) {
    return (val / 1000000000).toFixed(2) + 'B';
  } else if (val < 1000000000000000) {
    return (val / 1000000000000).toFixed(2) + 'T';
  } else {
    return (val / 1000000000000000).toFixed(2) + 'Q';
  }
};
